import {Box, CircularProgress, makeStyles, Typography, useTheme} from "@material-ui/core"
import endpoints from "endpoints";
import {ICourseData, ILevelData} from "pages/courses/ICourseData";
import React, {useEffect, useState} from "react";
import {useMemo} from "react";
import useSWR from "swr";
import useSharedStyles from "components/useSharedStyles";
import {ITeacherData} from "types/ITeacherData";
import {format} from "date-fns";
import useCurrentUser from "loaders/useCurrentUser";
import {Alert} from "@material-ui/lab";
import classNames from "classnames";
import {
  generateBulkStudentsProgressReportView,
  IProgressReportRawData,
  IProgressReportView,
  IProgressReportCourse, IProgressReportOrderedMission, IProgressReportOrderedBadge
} from "pages/reports/reportUtilities";
import {justFetch} from "mutations/mutate";
import useDialogState from "hooks/useDialogState";
import TeacherPaywallDialog from "components/dialogs/paywalls/TeacherPaywallDialog";
import {IStudentSchoolProfile} from "../../types/IStudentSchoolProfile";
import CourseProgressBar, {BadgeProgressBar, CourseBadgeProgressBar} from "./CourseProgressBar";
import {badgeData} from "../courses/BadgeData";
import {GameTitle} from "../../enums/GameTitle";
import {
    majorBadgeOuterSprites,
    missionColorMap,
    missionNameMap
} from "../courses/components/defaultMissionConfigurations";
import StarIcon from "@material-ui/icons/Star";
import {LegacyView} from "./StudentProgressLegacy";
import ProgressHeader from "./ProgressHeader";
import {ClassProgressStudentRowCell, ProgressTable, SingleProgressTable} from "./ProgressTable";
import {filter} from "lodash";
import {IKlass, IKlassAssignments} from "../../types/IKlass";

const NewContentBanner: React.VFC = () =>
{
  const theme = useTheme();

  return (
  <Box width="100%" display="flex" justifyContent="center" alignItems="center">
    <Box
      bgcolor={theme.palette.red.main}
      height={45}
      zIndex={10001}
      color="white"
      top={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius={10}
      marginBottom={2}
      paddingLeft={2}
      paddingRight={2}
    >
      <Box
        flexGrow={1}
        textAlign="center"
        color="white"
        style={{
          fontWeight: 400,
          fontSize: '18px'
        }}
      >
          <span style={{ fontWeight: 700 }}>Don’t panic!</span> Student progress hasn’t gone anywhere. We’ve added a ton of new content to Kodable this year. Update your apps and have students log in to see it.
      </Box>
    </Box>
  </Box>
  )
}

const columnWidth = 220;
const headerHeight = 175;
const levelColumnWidth = 100;
const missionColumnWidth = 110;

const StudentsProgress: React.VFC<{
  classIds: number[],
  selectedCourseId?: string,
  selectedStudentId?: string,
  onSelectCourse?: (courseId?: string) => void,
  onSelectStudent?: (studentId?: string) => void,
  onViewCourseDetails?: (courseId?: string) => void
}> = ({classIds, selectedCourseId, selectedStudentId, onSelectCourse, onSelectStudent, onViewCourseDetails}) => {
  const {currentUser} = useCurrentUser();
  const [legacyView, setLegacyView] = useState(false);

  const {data: teacherData, error: teacherDataError} = useSWR<ITeacherData>(endpoints.teacherInit);
  const {data: courseData, error: courseDataError} = useSWR<ICourseData[]>(endpoints.allBasicsCourses);
  const {data: creatorCourseData, error: creatorCourseDataError} = useSWR<ICourseData[]>(endpoints.allCreatorCourses);
    
  const assignments = useSWR<IKlass>(endpoints.classById(classIds[0], ['assignments'])).data?.assignments;
    
  const {
    data: legacyCourseData,
    error: legacyCourseDataError
  } = useSWR<ICourseData[]>(endpoints.allBasicsCoursesLegacy);
  const {data: levelData, error: levelDataError} = useSWR<ILevelData[]>(endpoints.allBasicsLevels);
  const [reportData, setReportData] = useState<{ [key: string]: IProgressReportRawData }>();
  const [reportDataError, setReportDataError] = useState(false);
  const [displayState, setDisplayState] = useState('assigned');
  const [selectedGame, setSelectedGame] = useState<GameTitle>(GameTitle.BASICS);

  useEffect(() => {
    if (reportData) {
      const hasClass = classIds.map((classId => classId in reportData));
      if (!hasClass.includes(false)) {
        return;
      }
    }
    justFetch(endpoints.bulkV2Reports, 'POST', {
      klass_ids: classIds
    })
      .then(res => res.json() as Promise<{ [key: string]: IProgressReportRawData }>)
      .then(setReportData)
      .catch(() => setReportDataError(true));
  }, [classIds]);

  const loading = (!teacherData && !teacherDataError) || (!courseData && !courseDataError) || (!creatorCourseData && !creatorCourseDataError) || (!legacyCourseData && !legacyCourseDataError) || (!reportData && !reportDataError) || (!levelData && !levelDataError);
  const error = teacherDataError || courseDataError || creatorCourseDataError || legacyCourseDataError || reportDataError;

  const uniqueStudents = teacherData?.students
    .reduce((acc, student) => {
      if (!acc.some(s => s.id === student.id)) {
        acc.push(student);
      }
      return acc;
    }, [] as typeof teacherData.students);

  const students = useMemo(() => uniqueStudents?.filter((student) => student.klasses.some(klassId => classIds.includes(klassId)) && !student.is_teacher).sort((a, b) => a.id! - b.id!) || [], [uniqueStudents]);
  const selectedStudentIndex = useMemo(() =>  students.findIndex(({ id }) => id.toString() === selectedStudentId), [students, selectedStudentId]);

  const reportView = useMemo(() => {
    if (!courseData || !creatorCourseData || !legacyCourseData || !teacherData || !reportData || !levelData) {
      return null;
    }
    return generateBulkStudentsProgressReportView(
      courseData!,
      creatorCourseData!,
      legacyCourseData!,
      levelData!,
      students,
      reportData,
      currentUser.plan === 'School',
      classIds.length > 1)
  }, [courseData, students, reportData, levelData, currentUser]);

  const selectedCourse = useMemo(() => reportView?.orderedCourses.find(orderedCourse => orderedCourse.courseId.toString() === selectedCourseId), [reportView, selectedCourseId])

  const teacherPaywallDialogState = useDialogState();

  if (loading) {
    return <Box display="flex" flexDirection="row" justifyContent="center">
      <CircularProgress/>
    </Box>
  }

  if (error) {
    return <Alert severity="error">There was an error generating this report.</Alert>
  }

  if (students?.length === 0) {
    return <Alert severity="info">You don't have any students in this class.</Alert>
  }

  return <Box display="flex" flexDirection="column" alignItems="flex-start">
    <TeacherPaywallDialog {...teacherPaywallDialogState} plan="Premium" copy="Tool"/>
    <ProgressHeader
      selectedStudentIndex={selectedStudentIndex}
      onSelectCourse={onSelectCourse}
      onSelectStudent={onSelectStudent}
      onViewCourseDetails={onViewCourseDetails}
      selectedStudentId={selectedStudentId}
      selectedCourseId={selectedCourseId}
      reportView={reportView}
      students={students}
      displayState={displayState}
      setDisplayState={setDisplayState}
      selectedGame={selectedGame}
      setSelectedGame={setSelectedGame}
      legacyView={legacyView}
      setLegacyView={setLegacyView}
      selectedCourse={selectedCourse}
    />

    {legacyView ? (
      <LegacyView
        classIds={classIds}
        students={students}
        selectedCourseId={selectedCourseId}
        onSelectCourse={onSelectCourse}
        reportView={reportView}
        selectedCourse={selectedCourse}
        displayState={displayState}
        selectedGame={selectedGame}
      />
    ) : (

      <StudentProgressView
        classIds={classIds}
        students={students}
        selectedCourseId={selectedCourseId}
        onSelectCourse={onSelectCourse}
        selectedStudentIndex={selectedStudentIndex}
        onSelectStudent={onSelectStudent}
        reportView={reportView}
        selectedCourse={selectedCourse}
        displayState={displayState}
        selectedGame={selectedGame}
        assignments={assignments}
      />
    )}

  </Box>
}


export interface ProgressReportViewProps {
  classIds: number[];
  selectedCourseId?: string
  selectedStudentIndex?: number;
  onSelectCourse?: (selectedCourseId?: string) => void
  onSelectStudent?: (selectedStudentIndex?: string) => void
  reportView?: IProgressReportView | null,
  students: IStudentSchoolProfile[],
  selectedCourse?: IProgressReportCourse,
  displayState: string,
  selectedGame: GameTitle,
  assignments?: IKlassAssignments  
}

const StudentProgressView: React.VFC<ProgressReportViewProps> = ({
                                                                   classIds,
                                                                   selectedCourseId,
                                                                   selectedStudentIndex,
                                                                   onSelectCourse,
                                                                   onSelectStudent,
                                                                   reportView,
                                                                   students,
                                                                   selectedCourse,
                                                                   displayState,
                                                                   selectedGame,
                                                                   assignments
                                                                 }) => {

  const showBanner = () =>
  {
    const today = new Date();
    const cutoffDate = new Date(2024, 9, 1); // oct 1
    if (today >= cutoffDate) {
      // no banner after oct 1
      return false;
    }

    const hasLegacyStudents = reportView?.orderedCourses.some(course => course.legacyStatus.some(studentLegacyStatus => studentLegacyStatus === true))

    // show banner if we have legacy students, and before oct 1
    return hasLegacyStudents;
  }

  return <>
    {!selectedCourse ? (
      <> {selectedStudentIndex !== -1 ? (
        <IndividualProgressView classIds={classIds} selectedStudentIndex={selectedStudentIndex} students={students}
                                displayState={displayState} reportView={reportView} selectedGame={selectedGame} assignments={assignments}/>
      ) : (
        <>
        {showBanner() && <NewContentBanner/>}
        <OverviewProgressView classIds={classIds} students={students} displayState={displayState}
                              reportView={reportView} onSelectCourse={onSelectCourse} onSelectStudent={onSelectStudent}
                              selectedGame={selectedGame} assignments={assignments}/>
        </>
      )}</>
    ) : (
      <CourseProgressView classIds={classIds} students={students} displayState={displayState} reportView={reportView}
                          selectedCourseId={selectedCourseId} selectedCourse={selectedCourse}
                          onSelectStudent={onSelectStudent} selectedGame={selectedGame} assignments={assignments}/>
    )}
  </>
}

const OverviewProgressView: React.VFC<ProgressReportViewProps> = ({
                                                                    classIds,
                                                                    onSelectCourse,
                                                                    onSelectStudent,
                                                                    reportView,
                                                                    students,
                                                                    selectedCourse,
                                                                    displayState,
                                                                    selectedGame,
                                                                    assignments
                                                                  }) => {
  const studentsProgressStyles = useStudentsProgressStyles();
  const {currentUser} = useCurrentUser();

  const filteredCourses = useMemo(() => {
    return reportView?.orderedCourses.filter(course => {
        if (course.courseId > 1000){
            if (selectedGame === GameTitle.BASICS){
                return false;
            }
            if (!assignments?.creator_courses.includes(course.courseId - 1000) && displayState !== "all"){
                return false;
            }
        } else{
            if (selectedGame === GameTitle.CREATOR){
                return false;
            }
            if (!assignments?.courses.includes(course.courseId) && displayState !== "all"){
                return false;
            }
        }
        if (course.orderedBadges && course.orderedBadges.length > 0){
            return true;
        }
        if (!course.orderedMissions || course.orderedMissions.length === 0) {
            return false;
        }
        if (displayState === "all"){
            return true;
        }
        const missions = course.orderedMissions.filter(mission => {
            if(mission.premium && currentUser.plan !== "School"){
                return false;
            }
            return !assignments?.mission_omissions.includes(`${course.courseId}-${mission.missionType}`);
        });
      return missions.length > 0;
    })
  }, [reportView, selectedGame])
    
  const width = () => {
      let width = 0;
      if (!filteredCourses){
          return width;
      }
      filteredCourses.forEach(courseReportData => {
          if (!courseReportData.orderedMissions || courseReportData.orderedMissions.length === 0) {
              if (!courseReportData.orderedBadges) {
                  width += columnWidth;
                  return;
              }
              let badgeTiers = 0;
              courseReportData.orderedBadges.forEach((badge) => {
                  if (badge.size === "major") badgeTiers += badge.tiers.length;
              });

              if (badgeTiers <= 1){
                width += columnWidth;
              } else {
                width += missionColumnWidth * badgeTiers;
              }
              return;
          }
          let missionCount = courseReportData.orderedMissions.length || 0;
          if (displayState === "assigned") {
              courseReportData.orderedMissions.forEach((mission) => {
                  if (currentUser.plan !== "School" && mission.premium) {
                      missionCount -= 1;
                  }else if(assignments?.mission_omissions.includes(`${courseReportData.courseId}-${mission.missionType}`)){
                      missionCount -= 1;
                  }
              });
          }
          if (missionCount === 1){
                width += columnWidth;
          } else{
                width += missionColumnWidth * missionCount;
          }
      });
      return width;
  }
  
  return <>
    <ProgressTable
      topLeft={
        <Typography variant="body2" style={{marginBottom: '.75rem', fontSize: '12px'}}>
          Click on a course or a student for in-depth progress reporting
        </Typography>
      }
      header={
        <>{filteredCourses?.map(courseReport => {
          return <OverviewHeader
            key={courseReport.courseId}
            courseReportData={courseReport}
            onSelectCourse={onSelectCourse ? (selectedCourseId => onSelectCourse?.(selectedCourseId)) : undefined}
            displayState={displayState}
            assignments={assignments}
          />
        })}</>
      }
      firstColumn={<>{students.map((student) => {
          return <ClassProgressStudentRowCell key={student.id}>
            <Typography variant="subtitle1"
                        className={studentsProgressStyles.clickableStudent}
                        onClick={() => {
                          onSelectStudent?.(student.id.toString());
                        }}>{student.name}</Typography>
            Last seen {student.tracked_date ? format(new Date(student.tracked_date), 'M/d/yyyy') : 'never'}
          </ClassProgressStudentRowCell>
        }
      )}</>
      }
      content={<>
        {filteredCourses?.map(courseReport => {
          return <CourseColumn key={courseReport.courseId} courseReportData={courseReport} displayState={displayState} assignments={assignments}/>
        })}
      </>
      }
      width={width()}
    />
  </>
}

const CourseProgressView: React.VFC<ProgressReportViewProps> = ({
                                                                  classIds,
                                                                  selectedCourseId,
                                                                  onSelectStudent,
                                                                  reportView,
                                                                  students,
                                                                  selectedCourse,
                                                                  displayState,
                                                                  selectedGame,
                                                                  assignments
                                                                }) => {
  const studentsProgressStyles = useStudentsProgressStyles();
  const {currentUser} = useCurrentUser();
  
  const filteredMissions = useMemo(() => {
    if (!selectedCourse || !selectedCourse.orderedMissions || selectedCourse.orderedMissions.length === 0) {
        return undefined;
    }
    if (displayState === "all") {
        return selectedCourse.orderedMissions;
    }
    return selectedCourse!.orderedMissions.filter(mission => {
      if(mission.premium && currentUser.plan !== "School"){
        return false;
      }
      return !assignments?.mission_omissions.includes(`${selectedCourse.courseId}-${mission.missionType}`);
    });
  }, [selectedCourse, displayState]);
  
  const width = () => {
      if (filteredMissions && filteredMissions.length > 0) {
          let segmentCount = 0;
          filteredMissions.forEach((mission) => {
                if (displayState === "all" || !mission.premium || currentUser.plan === "School") {
                    segmentCount += mission.orderedLessons?.length || 0;
                }
            });
          return segmentCount <= 1 ? columnWidth : levelColumnWidth * segmentCount;
      }
        let badgeTiers = 0;
        selectedCourse?.orderedBadges?.forEach((badge) => {
            badgeTiers += badge.tiers.length;
        });
        return badgeTiers <= 1 ? columnWidth : levelColumnWidth * badgeTiers;
  }
  return <ProgressTable
    topLeft={
      <Box mb={2} display="flex" flexDirection="column" alignItems="center" width='100%'>
        <img
          src={selectedCourse!.galaxyImage ? `images/courses/planets/${selectedCourse!.galaxyImage}.png` : selectedCourse!.iconUrl}
          alt={`${selectedCourse!.dashboardTitle} icon`}
          style={{
            height: 100,
          }}
        />
        <Typography variant="h2">{selectedCourse!.dashboardTitle}</Typography>
      </Box>
    }
    header={
      <CourseHeader courseId={selectedCourseId!} report={reportView!} displayState={displayState} missions={filteredMissions}/>
    }
    firstColumn={students.map((student, index) => {
      return <React.Fragment key={index}>
        <ClassProgressStudentRowCell key={student.id}>
          <Typography variant="subtitle1"
                      className={studentsProgressStyles.clickableStudent}
                      onClick={() => {
                        onSelectStudent?.(student.id.toString());
                      }}>{student.name}</Typography>
          Last seen {student.tracked_date ? format(new Date(student.tracked_date), 'M/d/yyyy') : 'never'}
        </ClassProgressStudentRowCell>
      </React.Fragment>
    })
    }
    content={<>
      {filteredMissions && filteredMissions.length > 0 ? (<>
        {
            filteredMissions.map(mission => {
            return <MissionColumn key={mission.missionType} mission={mission} students={students}/>
          })}
      </>) : (<>
        {
          selectedCourse?.orderedBadges?.map(badge => {
            return <BadgeColumn key={badge.badgeId} badge={badge} students={students}/>
          })}
      </>)}
    </>
    }
    width={width()}
  />
}


const IndividualProgressView: React.VFC<ProgressReportViewProps> = ({
                                                                      classIds,
                                                                      selectedStudentIndex,
                                                                      reportView,
                                                                      students,
                                                                      displayState,
                                                                      selectedGame,
                                                                      assignments
                                                                    }) => {
  const {currentUser} = useCurrentUser();

  const filteredCourses = useMemo(() => {
    return reportView?.orderedCourses.filter(course => {
      return (course.orderedMissions && course.orderedMissions.length > 0) || (course.orderedBadges && course.orderedBadges.length > 0)
    })
    // todo: filter base on omissions
  }, [reportView])

    function filteredMissions(course: IProgressReportCourse) {
        if (!course || !course.orderedMissions || course.orderedMissions.length === 0) {
            return undefined;
        }
        if (displayState === "all") {
            return course.orderedMissions;
        }
        return course!.orderedMissions.filter(mission => {
            if(mission.premium && currentUser.plan !== "School"){
                return false;
            }
            return !assignments?.mission_omissions.includes(`${course.courseId}-${mission.missionType}`);
        });
    }

  return <React.Fragment>
    {filteredCourses?.map((course, index) => {
      const missions = filteredMissions(course);
      return <React.Fragment key={index}>
        <SingleProgressTable
          topLeft={
            <Box mb={2} display="flex" flexDirection="column" alignItems="center" width='100%'>
              <img
                src={course.galaxyImage ? `images/courses/planets/${course.galaxyImage}.png` : course.iconUrl}
                alt={`${course.dashboardTitle} icon`}
                style={{
                  height: 100,
                }}
              />
              <Typography variant="h2">{course.dashboardTitle}</Typography>
            </Box>
          }
          header={
            <CourseHeader courseId={ course.courseId.toString()} report={reportView!} displayState={displayState} showPractice={true} missions={missions}/>
          }
          content={course.orderedMissions && course.orderedMissions.length > 0 ? (
            <>
              {
                  missions?.map(mission => <SelectedStudentMissionColumn key={mission.missionType} mission={mission} selectedStudentIndex={selectedStudentIndex} students = {students}/>)
              }
            </>
          ) : (
            <>
              {
                course.orderedBadges?.map((badge, index) => {
                  return <BadgeColumn key={index} badge={badge} students={students}
                                    selectedStudentIndex={selectedStudentIndex}/>
                })
              }
            </>
          )}
        />
        <Box height='25px' />
      </React.Fragment>
    })}
  </React.Fragment>
}

export interface OverviewHeaderProps {
  courseReportData: IProgressReportView['orderedCourses'][0];
  onSelectCourse?: (selectedCourseId?: string) => void;
  displayState: string;
  assignments?: IKlassAssignments;
}

const OverviewHeader: React.VFC<OverviewHeaderProps> = ({courseReportData, onSelectCourse, displayState, assignments}) => {
  const sharedClasses = useSharedStyles();
  const {currentUser} = useCurrentUser();
  const classCoursesProgressHeaderStyles = useClassCoursesProgressHeaderStyles();

  function missionName(missionType: string) {
    return missionNameMap[missionType];
  }

  function missionColor(missionType: string) {
    return missionColorMap[missionType];
  }

    const filteredMissions = useMemo(() => {
        if (!courseReportData || !courseReportData.orderedMissions || courseReportData.orderedMissions.length === 0) {
            return undefined;
        }
        if (displayState === "all") {
            return courseReportData.orderedMissions;
        }
        return courseReportData!.orderedMissions.filter(mission => {
            if(mission.premium && currentUser.plan !== "School"){
                return false;
            }
            return !assignments?.mission_omissions.includes(`${courseReportData.courseId}-${mission.missionType}`);
        });
    }, [courseReportData, displayState]);

  function width() {
    if (!filteredMissions || filteredMissions.length === 0) {
      if (!courseReportData.orderedBadges) {
        return columnWidth
      }
      let badgeTiers = 0;
      courseReportData.orderedBadges.forEach((badge) => {
        if (badge.size === "major") badgeTiers += badge.tiers.length;
      });

      return badgeTiers <= 1 ? columnWidth : missionColumnWidth * badgeTiers;
    }
    let missionCount =  filteredMissions.length || 0;
    return missionCount === 1 ? columnWidth : missionColumnWidth * missionCount;
  }

  return (
    <Box
      display="flex"
      height={headerHeight}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      style={{
        minWidth: width(),
        maxWidth: width()
      }}
      className={classCoursesProgressHeaderStyles.cell}>
      <Box
        mb={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        width='100%'
        className={onSelectCourse ? classNames({
          [sharedClasses.hoverCursorPointer]: !courseReportData.emptyCourse,
          [classCoursesProgressHeaderStyles.clickableTitle]: !courseReportData.emptyCourse
        }, classCoursesProgressHeaderStyles.title) : ''}
        onClick={() => {
          if (!courseReportData.emptyCourse) {
            onSelectCourse?.(courseReportData.courseId.toString())
          }
        }}
      >
        <img
          src={courseReportData.galaxyImage ? `images/courses/planets/${courseReportData.galaxyImage}.png` : courseReportData.iconUrl}
          alt={`${courseReportData.dashboardTitle} icon`}
          style={{
            height: 100,
          }}
        />
        <Typography
          variant="h2"
          color={onSelectCourse ? 'primary' : 'textPrimary'}
        >
          {courseReportData.dashboardTitle}
        </Typography>

      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" width="100%">
        {filteredMissions && filteredMissions.length > 0 ? (<>
          {filteredMissions.map(mission => {
            return <Box key={mission.missionType} width={missionColumnWidth} alignItems='center'>
              <Typography style={{
                color: missionColor(mission.missionType),
                fontSize: '12px',
                fontWeight: '600'
              }}>{missionName(mission.missionType)}</Typography>
            </Box>
          })}
        </>) : (<>
          {courseReportData.orderedBadges?.filter(badge => badge.size === 'major').map((badge, badgeIndex) => {
            return <React.Fragment key={badgeIndex}>{badge.tiers.map((tier, index) => {
              return <Box key={tier.value} width={missionColumnWidth} alignItems='center'>
                <Typography style={{
                  color: missionColor(index.toString()),
                  fontSize: '12px',
                  fontWeight: '600'
                }}>{tier.value}</Typography>
              </Box>
            })}</React.Fragment>
          })}
        </>)}
      </Box>
    </Box>
  )
}
const CourseColumn: React.VFC<{
  courseReportData: IProgressReportView['orderedCourses'][0];
  displayState: string
    assignments?: IKlassAssignments
}> = ({courseReportData, displayState, assignments}) => {
  const classCoursesProgressHeaderStyles = useClassCoursesProgressHeaderStyles();
  const {currentUser} = useCurrentUser();

    const filteredMissions = useMemo(() => {
        if (!courseReportData || !courseReportData.orderedMissions || courseReportData.orderedMissions.length === 0) {
            return undefined;
        }
        if (displayState === "all") {
            return courseReportData.orderedMissions;
        }
        return courseReportData!.orderedMissions.filter(mission => {
            if(mission.premium && currentUser.plan !== "School"){
                return false;
            }
            return !assignments?.mission_omissions.includes(`${courseReportData.courseId}-${mission.missionType}`);
        });
    }, [courseReportData, displayState]);
  const width = () => {
    if (!filteredMissions|| filteredMissions.length === 0) {
      if (!courseReportData.orderedBadges) {
        return columnWidth
      }
      let badgeTiers = 0;
      courseReportData.orderedBadges.forEach((badge) => {
        if (badge.size === "major") badgeTiers += badge.tiers.length;
      });

      return badgeTiers <= 1 ? columnWidth : missionColumnWidth * badgeTiers;
    }
    let missionCount = filteredMissions.length || 0;
    return missionCount === 1 ? columnWidth : missionColumnWidth * missionCount;
  }

  return <Box className={classCoursesProgressHeaderStyles.column}>
    {
      courseReportData.orderedProgress.map((progress, idx) => {
        return <ClassProgressStudentRowCell key={idx} width={width()}>
          {!courseReportData.legacyStatus[idx] ? (
            <CourseProgressBar studentIndex={idx} course={courseReportData} displayState={displayState} missions={filteredMissions}/>
          ) : (
            <Box textAlign="center" width="100%">
              Update to View
            </Box>
          )}
        </ClassProgressStudentRowCell>
      })

    }
  </Box>
}
const CourseHeader: React.VFC<{ courseId: string, report: IProgressReportView, displayState: string, missions?: IProgressReportOrderedMission[], showPractice?: boolean }> = ({
                                                                                                            courseId,
                                                                                                            report,
                                                                                                            displayState,
                                                                                                            missions,
                                                                                                            showPractice= false
                                                                                                          }) => {
  const courseReportData = useMemo(() => {
    return report.orderedCourses.find(courseReportView => courseReportView.courseId.toString() === courseId)!;
  }, [courseId, report]);

  const classCoursesProgressHeaderStyles = useClassCoursesProgressHeaderStyles();
  const missionHeaderStyles = useMissionHeaderStyles();
  const {currentUser} = useCurrentUser();
    
  const courseBadgeData = useMemo(() => {
      if (!missions || missions.length === 0) {
        return undefined;
      }
      return badgeData.find(b => b.courseId === courseReportData.courseId);
  }, [courseReportData, missions])  
    
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-end"
      className={classCoursesProgressHeaderStyles.cell}
      height={`${headerHeight}px`}
    >
      <Box display="flex" flexDirection="row" height="100%">
        {missions && missions.length > 0 ? (
          <>
            {missions?.map(mission => (
            <Box key={mission.missionType}
                 display="flex"
                 flexDirection="column"
                 alignItems="center"
                 textAlign="center"
                 justifyContent="space-between"
                 className={missionHeaderStyles.mission}
                 height="100%"
                 width={`${levelColumnWidth * (mission.orderedLessons!.length + (showPractice ? 1: 0))}px)`}
            >
              <Box flexGrow="1" display="flex" flexDirection="column" alignItems="center" justifyContent="center">

                  <Box style={{maxHeight: 80, minHeight: 80}}>
                      {courseBadgeData?.size === 'minor' ? (<>
                          <img src={'/images/badges/outer/badge_minor_stageFour.png'}
                               style={{position: "absolute", marginTop: 0, marginLeft: -40,  height: "80px", width: "auto"}}/>

                          <img src={`/images/badges/inner/minor/${courseBadgeData!.icon}.png`}
                               style={{position: "absolute", marginTop: 0, marginLeft: -40, height: "80px", width: "auto"}}/>
                      </>
                  ):(<>
                      <img src={majorBadgeOuterSprites[mission.missionType]}
                           style={{position: "absolute", marginTop: 0, marginLeft: -40,  height: "80px", width: "auto"}}/>

                      <img src={`/images/badges/inner/major/${courseBadgeData!.icon}.png`}
                           style={{position: "absolute", marginTop: 0, marginLeft: -40, height: "80px", width: "auto"}}/>
                  </>)}
              </Box>
                <Typography style={{
                  color: missionColorMap[mission.missionType]
                }}>{courseReportData.dashboardSubtitle} {missionNameMap[mission.missionType]}</Typography>
              </Box>
              <Box display="flex"
                   flexDirection="row"
                   alignItems="center"
                   textAlign="center"
                   justifyContent="space-between" height={40}>
                {mission.orderedLessons?.map((lesson, index) => {
                  return (<>
                      {index === mission.orderedLessons!.length - 1 ? (<></>) : (
                        <Box key={lesson.lessonId}
                             width={levelColumnWidth}
                             height={"100%"}
                             pt={1}
                             justifyContent="center"
                             className={missionHeaderStyles.lesson}
                        >
                          <Typography key={lesson.lessonId}>Lesson {lesson.lessonIndex + 1}</Typography>
                        </Box>)}
                    </>
                  )
                })}
                  {showPractice && <Box key="practice"
                       width={levelColumnWidth}
                       height={"100%"}
                       pt={1}
                       justifyContent="center"
                       className={missionHeaderStyles.lesson}
                  >
                      <Typography>Practice</Typography>
                  </Box>}
                <Box key="assessment"
                     width={levelColumnWidth}
                     height={"100%"}
                     pt={1}
                     justifyContent="center"
                     className={missionHeaderStyles.lesson}
                >
                  <Typography>Assessment</Typography>
                </Box>
              </Box>
            </Box>
          ))}
          </>
        ) : (
          <>
            {
              courseReportData.orderedBadges?.map(badge => {
                const badgeInfo = badgeData.find(b => b.id === badge.badgeId);
                return (
                  <Box key={badge.badgeId}
                       display="flex"
                       flexDirection="column"
                       alignItems="center"
                       textAlign="center"
                       justifyContent="space-between"
                       height="100%"
                       className={missionHeaderStyles.badge}
                       width={`${levelColumnWidth * (badgeInfo!.tierValues.length)}px)`}
                  >
                    <Box flexGrow="1" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        {badgeInfo && <Box style={{maxHeight: 80, minHeight: 80}}>
                            {badgeInfo?.size === "major" ? (<>
                                <img src={"/images/badges/outer/badge_major_stageFour.png"}
                                     style={{position: "absolute", marginTop: 0, marginLeft: -40,  height: "80px", width: "auto"}}/>

                                <img src={`/images/badges/inner/major/${badgeInfo.icon}.png`}
                                     style={{position: "absolute", marginTop: 0, marginLeft: -40, height: "80px", width: "auto"}}/>
                            </>) : (<>

                                <img src={"/images/badges/outer/badge_minor_stageFour.png"}
                                     style={{
                                         position: "absolute",
                                         marginTop: 0,
                                         marginLeft: -40,
                                         height: "80px",
                                         width: "auto"
                                     }}/>

                                <img src={`/images/badges/inner/minor/${badgeInfo!.icon}.png`}
                                     style={{
                                         position: "absolute",
                                         marginTop: 0,
                                         marginLeft: -40,
                                         height: "80px",
                                         width: "auto"
                                     }}/>
                            </>)}
                        </Box>}

                        <Typography style={{
                            fontSize: '16px',
                            fontWeight: '600'
                        }}>{badgeInfo?.name}</Typography>
                        <Typography style={{fontSize: '14px'}}>{badgeInfo?.description}</Typography>
                    </Box>
                      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                      {badgeInfo?.tierValues.map((tier, index) => {
                        return (
                          <Box key={tier} width={levelColumnWidth} height="100%" display="flex"
                               flexDirection="column" alignItems="center" justifyContent="flex-end" pb={"5px"}>
                            <Typography style={{
                              fontSize: '14px',
                              color: missionColorMap[index.toString()],
                            }}>{tier}</Typography>
                          </Box>)
                      })}
                    </Box>
                  </Box>
                )
              })
            }
          </>
        )}
      </Box>
    </Box>
  );
}
const MissionColumn: React.VFC<{ mission: IProgressReportOrderedMission, students: IStudentSchoolProfile[] }> = ({
                                                                                   mission,
                                                                                   students
                                                                               }) => {
    const classCoursesProgressHeaderStyles = useClassCoursesProgressHeaderStyles();

    return <Box display="flex" flexDirection="column">
    {students.map((student, studentIndex) => {
        return <Box key={studentIndex} className={classCoursesProgressHeaderStyles.column}>
            <ClassProgressStudentRowCell width={levelColumnWidth * mission.orderedLessons!.length} key={studentIndex}>
                <CourseBadgeProgressBar width={levelColumnWidth * mission.orderedLessons!.length} mission={mission} studentIndex={studentIndex} />
            </ClassProgressStudentRowCell>
        </Box>
    })}
        </Box>
}
const SelectedStudentMissionColumn: React.VFC<{ mission: IProgressReportOrderedMission, selectedStudentIndex?: number, students: IStudentSchoolProfile[] }> = ({
                                                                                                               mission,
                                                                                                               selectedStudentIndex,
                                                                                                                students
                                                                                                             }) => {
  const classCoursesProgressHeaderStyles = useClassCoursesProgressHeaderStyles();

  function completionColor(stars: number) {
    switch (stars) {
      case 0:
        return '#c5c5c5';
      case 1:
        return '#da4d55';
      case 2:
        return '#ffb40f';
      case 3:
        return '#61bb46';
    }
    return '#c5c5c5';
  }

  function completionIcon(stars?: number) {
    const verifiedStars = stars ? stars : 0;
    return (
      <Box style={{
        backgroundColor: completionColor(verifiedStars),
        height: "25px",
        width: "25px",
        borderRadius: "50%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: "3px",
        color: "#fff"
      }}>
        {verifiedStars !== 0 && (<>

          <Typography style={{fontSize: "12px"}}>{verifiedStars}</Typography>
          <StarIcon style={{height: "12px", width: "12px"}}/>
        </>)
        }
      </Box>
    )
  }

  return <>
    {mission.orderedLessons?.map((lesson, lessonIndex) => {
      return <>
        {lessonIndex === mission.orderedLessons!.length! - 1 &&
          <Box key="practice" className={classCoursesProgressHeaderStyles.column} width={levelColumnWidth}>
            {mission.practiceLevels.map((levels, studentIndex) => { /* assuming here mission.practiceLevels is students size, and each corresponds to practice level count completed per student. TODO double check */
              return <>
                { selectedStudentIndex && selectedStudentIndex !== studentIndex ? <React.Fragment key={studentIndex}/> : (
                  <ClassProgressStudentRowCell width={levelColumnWidth} key={studentIndex}>
                    <Typography style={{fontSize: '12px', alignItems: 'center', marginLeft: '8px'}}>{levels} levels
                      completed</Typography>
                  </ClassProgressStudentRowCell>
                )}</>
            })}
          </Box>}
        <Box key={lesson.lessonId} className={classCoursesProgressHeaderStyles.column} width={levelColumnWidth}>
          {
            students.map((student, studentIndex) => {
              return <React.Fragment key={studentIndex}>
                {selectedStudentIndex && selectedStudentIndex !== studentIndex ? <></> : (
                  <ClassProgressStudentRowCell width={levelColumnWidth} key={studentIndex}>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between"
                         width={"100%"} height={"100%"} zIndex={2}>
                      {lesson.orderedLevels.map((level) => {
                        return completionIcon(level.orderedProgress.at(studentIndex)?.stars)
                      })}
                    </Box>
                    <Box position="relatve"
                         style={{
                           backgroundColor: '#c5c5c5',
                           height: "3px",
                           width: "75px",
                           marginLeft: "5px",
                             marginTop : "-25px"
                         }}/>
                  </ClassProgressStudentRowCell>
                )}</React.Fragment>
            })}
        </Box>
      </>
    })}
  </>
}

const BadgeColumn: React.VFC<{
  badge: IProgressReportOrderedBadge,
  students: IStudentSchoolProfile[],
  selectedStudentIndex?: number
}> = ({badge, students, selectedStudentIndex}) => {

  return <Box display="flex" flexDirection="column" width="auto">
    {students.map((student, studentIndex) => {
      return <React.Fragment key={studentIndex}>
        {selectedStudentIndex && selectedStudentIndex !== studentIndex ? <></> : (<>
          <ClassProgressStudentRowCell key={studentIndex} width={badge.tiers.length * levelColumnWidth}>
            <Box display="flex" flexDirection="row" key={studentIndex} width="100%">
              <BadgeProgressBar badge={badge} studentIndex={studentIndex}/>
            </Box>
          </ClassProgressStudentRowCell>
        </>)
        }
      </React.Fragment>
    })}
  </Box>
}


export const useClassCoursesProgressHeaderStyles = makeStyles(theme => ({
  '@keyframes floaty': {
    '0%': {
      transform: 'translateY(0)',
      filter: 'drop-shadow(gray 0px 2px 4px)'
    },
    '50%': {
      transform: 'translateY(-5px)',
      filter: 'drop-shadow(gray 0px 7px 6px)'
    },
    '100%': {
      transform: 'translateY(0)',
      filter: 'drop-shadow(gray 0px 2px 4px)'
    },
  },
  cell: {
    border: `1px solid rgba(0, 0, 0, 0.2)`,
    borderRight: 'none',
    borderBottom: 'none',
    '&:is(:last-child)': {
      borderRight: 'solid 1px rgba(0, 0, 0, 0.2)',
      borderTopRightRadius: "25px"
    }
  },
  title: {
    '& img': {
      filter: 'drop-shadow(gray 0px 2px 4px)'
    }
  },
  clickableTitle: {
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.primary.main,
      '& img': {
        animation: '$floaty',
        animationDuration: '1.5s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'ease-in-out'
      }
    }
  },
  column: {
    '&:last-child': {
      borderRight: '1px solid rgba(0, 0, 0, 0.2)'
    }
  }
}));

const useMissionHeaderStyles = makeStyles(theme => ({
  lesson: {
    border: `1px solid rgba(0, 0, 0, 0.2)`,
    borderBottom: 'none',
    borderLeft: 'none',
    '&:is(:last-child)': {
      borderRight: 'none'
    }
  },
  badgeTier: {
    border: `1px solid rgba(0, 0, 0, 0.2)`,
    borderBottom: 'none',
    borderLeft: 'none',
  },
  badge: {
    border: `1px solid rgba(0, 0, 0, 0.2)`,
    borderBottom: 'none',
    borderLeft: 'none',
    borderTop: 'none',
    marginRight: '-1px',
    '&:is(:last-child)': {
      borderRight: 'none',
      borderTopRightRadius: "25px"
    }
  },
  mission: {
    border: `1px solid rgba(0, 0, 0, 0.2)`,
    borderBottom: 'none',
    borderLeft: 'none',
    '&:is(:last-child)': {
      borderRight: 'none',
      borderTopRightRadius: "25px"
    }
  },
}));

export const useStudentsProgressStyles = makeStyles(theme => ({
  stickyCourses: {
    position: 'sticky',
    top: '2rem',
    zIndex: 1
  },
  clickableStudent: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.primary.main,
    }
  },
  staticStudent: {},
}));

export default StudentsProgress;
